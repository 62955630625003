import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Modal from 'react-bootstrap/Modal';
import ImgMediaCard from './card';
import data from './data/data';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        height: 350,
        width: 300,
    },
}));

export default function SpacingGrid() {
    const [spacing] = React.useState(6);
    const [lgShow, setLgShow] = React.useState(false);
    const classes = useStyles();

    return (
        <Grid container className={classes.root} spacing={6}>
            <Grid item xs={12}>
                <Grid container justify="center" spacing={spacing}>
                    {data.map((value) => (
                        <Grid key={value.id} item>
                            <Paper className={classes.paper} onClick={() => setLgShow(true)}>
                                <div className={'blogSample'}>
                                    <img className={"blogImg"} src={value.img}/>
                                    <h4 className={"blogTitle"}>{value.title}</h4>
                                    <p>{value.artist}</p>
                                </div>
                            </Paper>
                            <Modal
                                size="lg"
                                show={lgShow}
                                onHide={() => setLgShow(false)}
                                aria-labelledby="example-modal-sizes-title-lg"
                            >
                                <Modal.Header closeButton>

                                </Modal.Header>

                                <Modal.Body closeButton>
                                    <div className={"info"}>
                                    <img className={"blogImg"} src={value.img}/>
                                    <h4 className={"blogTitle"}>{value.title}</h4>
                                    <p>{value.artist}</p>
                                    <p className={"blogaddn"}>{value.releasedate}&ensp; // &ensp;{value.length}&ensp; // &ensp;{value.label}</p>
                                    </div>
                                    <br/><br/>
                                    <div className={"blogcontent"} dangerouslySetInnerHTML={ {__html: value.reviewText} }/>



                                </Modal.Body>
                            </Modal>
                        </Grid>
                    ))}
                </Grid>
            </Grid>
        </Grid>
    );
}
