export const lightTheme = {
    body: '#E2E2E2',
    text: '#363537',
    toggleBorder: '#FFF',
    gradient: 'linear-gradient(#39598A, #79D7ED)',
    infocolor: '#4c4949'

}

export const darkTheme = {
    body: '#434144',
    text: '#FAFAFA',
    toggleBorder: '#6B8096',
    gradient: 'linear-gradient(#091236, #1E215D)',
    jumbotron: '#363537',
    multiPaperbg: '#5d5d5da8',
    blogTitle: '#d2d2d2',
    modalbg: '#5d5d5d',
    infocolor: '#e4e4e4'

}