import React, {Component, useState} from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import SpacingGrid from './grid';
import { ThemeProvider } from 'styled-components';
import { lightTheme, darkTheme } from './theme';
import { GlobalStyles } from './global';
import './App.css';
import Switch from '@material-ui/core/Switch';


// The function that toggles between themes
function App() {
    const [theme, setTheme] = useState('dark');
    const toggleTheme = () => {
        if (theme === 'light') {
            setTheme('dark');
        } else {
            setTheme('light');
        }
    };
    const [checked, setChecked] = React.useState(false);

    const toggleChecked = () => {
        setChecked((prev) => !prev);
    };

    // Return the layout based on the current theme
    return (
        <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
            <>
                <GlobalStyles />
                <Jumbotron>
                    <Switch onChange={toggleTheme} inputProps={{ 'aria-label': 'primary checkbox' }} size="large"/>

                    <Container >
                        <h1 className="header">Adi can't stop talking about music.</h1>

                        <br/>


                    </Container>
                    <Container className={"reviews"}>
                        <SpacingGrid/>
                    </Container>
                </Jumbotron>

            </>
        </ThemeProvider>
    );
}



export default App;
