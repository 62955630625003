import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  body {
    padding: 15px;
    background: ${({ theme }) => theme.body};
    color: ${({ theme }) => theme.text};
    transition: all 0.25s linear;
  }
  .jumbotron{
    background: ${({ theme }) => theme.jumbotron};
  }
  .MuiPaper-root{
    background-color: ${({ theme }) => theme.multiPaperbg};
  }
  .blogSample{
    color: ${({ theme }) => theme.blogTitle};
  }
  .modal-content {
    background-color: ${({ theme }) => theme.modalbg};
  }
  .info{
    color: ${({ theme }) => theme.infocolor};
  }
  // footer {
  //   position: absolute;
  //   bottom: 5%;
  //   left: 50%;
  //   transform: translateX(-50%);
  // }
  // small {
  //   display: block;
  // }
  // button {
  //   display: block;
  // }
  // a {
  //   color: ${({ theme }) => theme.text};
  // }
`;